import { useEffect } from 'react';
/**
 * Hook which lets child navigators add action to be executed on clicking outside of the element
 */
export default function useClickOutside(refObject, callback) {
  const handleClickOutside = (e) => {
    if (!(refObject?.current?.contains(e.target))) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
}
