import { Fragment, useEffect, useState } from 'react';
import { intl } from 'di18n-react';
import GetHtml from '@/utils/DOMPurify'
import classBind from 'classnames/bind';
import cls from 'classnames';
import { highlight } from '@/utils';
import Tag from '@/components/Tag';
import styles from './style.module.less';
import { Tooltip } from 'antd';
import { FileType } from '@/constants/cooper';
import { setImgUrl } from '@/utils/cooperutils';
import { dkPage } from '@/assets/icon/fileIcon';

const cx = classBind.bind(styles);

// TODO: 折行时hover弹tooltip; 全局用到的地方修改传参
const FileContent = ({
  iconImage = dkPage,
  resourceName,
  description,
  children,
  scopeName,
  resourceId,
  resourceOwner,
  publishDesc,
  refreshDesc,
  fileTypeStr,
  tags,
  isLarge = false,
  isInTable = false,
  onPositionClick,
  overlayClassName,
  isShowOuter = false,
}) => {
  const [visible, setVisible] = useState(false);
  const [svgData, setSvgData] = useState(null);

  const {
    FILE, DIR, COOPER_DOC, COOPER_SHEET, COOPER_SLICE, COOPER_ANONYMOUS_DOC,
    SHIMO2_WORD, SHIMO2_EXCEL, SHIMO2_PPT, FLOWCHART,
    DI_DOC, DK_PAGE, DK_FILE, PAGE_SHARE, DK_SHARE, DK_RESOURCE, KNOWLEDGE, SPACE, PERSONAL_SPACE,
  } = FileType;

  useEffect(() => {
    if (fileTypeStr === FileType.FLOWCHART) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', iconImage, true);
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          const svgContent = xhr.responseText;
          setSvgData(GetHtml(svgContent))
        } else {
          setSvgData(null);
        }
      };
      xhr.send();
    } else {
      setSvgData(null);
    }
  }, [fileTypeStr, iconImage]);

  return (
    <div
      key={resourceId}
      className={cx(
        'file-content-wrap',
        {
          'file-content-wrap-large': isLarge,
          'no-padding-left': isInTable,
        },
        overlayClassName,
      )}
    >
      {
        (fileTypeStr === FileType.FLOWCHART && svgData) ? (
          <div
            className={cx('file-name-list')}
            innerHTML={GetHtml(svgData)}
           />
        ) : (
          <img
            className={cls('file-icon')}
            src={setImgUrl({
              resourceType: fileTypeStr,
              tinyImage: iconImage,
            })}
          />
        )
      }

      <div className={cls('file-text-main')}>
        <div className={cx('title')}>
          {resourceName && (
            <p
              className={cx('title-text')}
              title={resourceName?.replace(/§§/g, '')}
            >
              {highlight(resourceName || '')}
            </p>
          )}
          {tags?.length > 0 && (
            <div className={cx('tag-list')}>
              {tags.map((tag, index) => {
                return (
                  <Fragment key={tag}>
                    <Tooltip
                      visible={visible}
                      title={
                        tag === intl.t('访客链接')
                          ? intl.t('通过开启知识库/页面分享方式获得的链接')
                          : null
                      }
                    >
                      <Tag
                        type="blue-primary"
                        text={tag}
                        onMouseOver={() => {
                          setVisible(true);
                        }}
                        onMouseOut={() => {
                          setVisible(false);
                        }}
                      />
                    </Tooltip>
                  </Fragment>
                );
              })}
            </div>
          )}
          {
          isShowOuter && <Tag
            type='out-yellow'
            text={intl.t('外部')}/>
          }
        </div>
        {description && (
          <p
            className={cx('description')}
            title={description?.replace(/§§/g, '')}
          >
            {highlight(description || '')}
          </p>
        )}
        {children ? (
          <div className={cx('addition')}>{children}</div>
        ) : (
          <div className={cx('addition')}>
            {
                fileTypeStr !== KNOWLEDGE
                && fileTypeStr !== SPACE
                && fileTypeStr !== PERSONAL_SPACE
                && (!!scopeName)
                  && (
                    <div className={cx('metis-name item-div')}>
                      <span
                        className={cx('metis-span item-span')}
                        onClick={(e) => {
                          e.stopPropagation();
                          onPositionClick && onPositionClick();
                        }}
                      >
                        {
                          <>
                            {
                        scopeName === '个人空间'
                          ? <>
                            <i className={cx('dk-iconfont', 'dk-icon-gerenkongjian', 'scope-icon')} />
                            {intl.t('个人空间')}
                          </>
                          : <>
                            <i
                              className={cx('dk-iconfont', {
                                'dk-icon-zhishikuzuixin': [DK_PAGE, DK_FILE, PAGE_SHARE, DK_SHARE, DK_RESOURCE].includes(fileTypeStr),
                                'dk-icon-tuanduikongjian4px': [FILE, DIR, COOPER_DOC, COOPER_SHEET, COOPER_SLICE, COOPER_ANONYMOUS_DOC, SHIMO2_WORD, SHIMO2_EXCEL, SHIMO2_PPT, FLOWCHART, DI_DOC].includes(fileTypeStr),
                              }, 'scope-icon')} />
                            {scopeName}
                          </>
                      }
                          </>
                  }

                      </span>
                    </div>
                  )
            }
            {resourceOwner && (
              <div
                title={resourceOwner}
                className={cx('owner item-div')}>
                <span className={cx('owner-span item-span')}>
                  {intl.t('所有者')}:
                  {resourceOwner}
                </span>
              </div>
            )}
            {publishDesc && (
              <div
                className={cx('publish item-div')}
                title={publishDesc}>
                <span className={cx('item-span')}>{publishDesc}</span>
              </div>
            )}
            {refreshDesc && (
              <div
                className={cx('refresh item-div')}
                title={refreshDesc}>
                <span className={cx('item-span')}>{refreshDesc}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileContent;
