import { post, get, del, put, patch } from '@/utils/request';
import api from '@/utils/request/api/DkApi';
import { encodeURIParamFn } from '@/utils';

export async function updateknowledge(params) {
  const res = await post(api.UPDATE_DK, params);
  return res;
}

export async function unboundSpace(params) {
  const res = await get(api.UMBOUND_SPACE, { params });
  return res;
}

export async function searchMembersWhenAddMember(params) {
  const res = await get(`${api.SEARCH_MEMBERS}${encodeURIParamFn(params)}`);
  return res;
}

export async function searchCustomMembersWhenAddMember(params) {
  const res = await get(`${api.SEARCH_CUSTOM_MEMBERS}${encodeURIParamFn(params)}`);
  return res;
}

export async function addGroup(knowledgeId, params) {
  const res = await post(api.ADD_GROUP.replace(':knowledgeId', knowledgeId), params, { keepSilent: true });
  return res;
}

export async function addDepartmentGroup(knowledgeId, params) {
  const res = await post(api.ADD_DEPARTMENT_GROUP.replace(':knowledgeId', knowledgeId), params);
  return res;
}

export async function updateGroup(knowledgeId, groupId, params) {
  const res = await post(`${api.UPDATE_GROUP.replace(':knowledgeId', knowledgeId)}?groupId=${groupId}`, params);
  return res;
}

export async function deleteGroup(knowledgeId, groupId) {
  const res = await del(`${api.DELETE_GROUP.replace(':knowledgeId', knowledgeId)}?groupId=${groupId}`);
  return res;
}

export async function searchGroupMember(knowledgeId, par) {
  
  const res = await get(`${api.SEARCH_GROUP.replace(':knowledgeId', knowledgeId)}${encodeURIParamFn(par)}`);
  return res;
}

export async function getExternalGroupMember(groupId, type, params) {
  const list = await get(api.GET_EXTERNAL_GROUP_MEMBER.replace(':groupId', groupId).replace(':type', type), { params });
  const newList = list.map(({ name, avatar, id, email }) => {
    return { memberName: name.chineseName, email, avatar, id, inviteeType: 0 };
  });
  return newList;
}

export async function getGroupMember(knowledgeId, params) {
  const res = await get(api.GET_GROUP_MEMBER.replace(':knowledgeId', knowledgeId), { params });
  const newList = res.map((item) => {
    return { ...item, memberName: item.name.chineseName };
  });
  return newList;
}

export async function addDkMember(knowledgeId, params) {
  const res = await post(api.ADD_DEL_MEMBERS.replace(':knowledgeId', knowledgeId), params);
  return res;
}

export async function batchAddDkMember(params) {
  const res = await post(api.BATCH_ADD_MEMBERS, params);
  return res;
}

export async function delDkMember(knowledgeId, params) {
  const res = await del(api.ADD_DEL_MEMBERS.replace(':knowledgeId', knowledgeId), { params });
  return res;
}

export async function changeMemberRole(knowledgeId, params) {
  const res = await put(api.CHANGE_MEMBERS_ROLE.replace(':knowledgeId', knowledgeId), params);
  return res;
}

export async function beDepartmentOrNot(params) {
  const res = await get(api.BE_DEPARTMENT_OR_NOT, { params });
  return res;
}

export async function transferDkOwner(params) {
  const res = await post(api.TRANSFER_DK_OWNER, params);
  return res;
}

// 设置
export async function applySetting(spaceId, params) {
  const res = await post(api.APPLY_DK.replace(':spaceId', spaceId), params);
  return res;
}
export async function verifySetting(spaceId, params) {
  const res = await get(api.APPLY_VERIFY.replace(':spaceId', spaceId), { params });
  return res;
}

// 页面
export async function applyPage(resourceId, params) {
  const res = await post(api.APPLY_PAGE.replace('{resourceId}', resourceId), params);
  return res;
}


export async function gainSetting(params) {
  const res = await get(api.SWITCH_GRUOP, { params });
  return res;
}
export async function alterSetting(data) {
  const res = await patch(api.SWITCH_GRUOP, data);
  return res;
}
export async function shareSetting(knowledgeId) {
  const res = await get(api.SWITCH_SHARING.replace(':knowledgeId', knowledgeId));
  return res;
}

export async function getTeamsList() {
  const res = await get(api.GET_TEAMS_ADMIN, {
    headers: {
      'X-App-Id': 2,
    },
  });
  return res;
}

export async function tranferKnowledge(data) {
  const res = await post(api.TRANS_KNOWLEDGE, data, {
    headers: {
      'X-App-Id': 2,
    },
  });
  return res;
}
