import { useState, useRef, useEffect, useMemo } from 'react';
import { Input, Popover } from 'antd';
import { cooperOptionDefault } from '@/constants/userPerm';
import useClickOutside from '@/hooks/useClickOutside';
import classBind from 'classnames/bind';

import PermOptionItem from '../PermOptionItem';
import styles from '../style.module.less';


const cx = classBind.bind(styles);

const CooperOption = ({ onSelectFinally, applyedPerm, onVisibleChange }) => {
  const [optionPerms, setOptionPerms] = useState([]);
  const optionRef = useRef(null);

  useEffect(() => {
    const formateCooperOption = cooperOptionDefault.map((option, _) => {
      return {
        ...option,
        checked: option.value === applyedPerm, // 需要改写
      }
    })

    setOptionPerms(formateCooperOption);
  }, []); // 增加perm依赖逻辑 ?


  // const permList = useMemo(() => {
  //   let perms = [];
  //   for (let i = 0; i < PERM_DEFAULT_LIST.length; i++) {
  //     if (perm & PERM_DEFAULT_LIST[i]) perms.push(i); // eslint-disable-line no-bitwise
  //   }
  //   return perms;
  // }, [perm]);

  // parmArr.forEach((v) => {
  //   permNum += folderPermsDefault[v].perm;
  // });


  // useClickOutside(optionRef, () => {
  //   const resOption = [];
  //   optionPerms.map((option, _) => { // 要么是单选。要么是多选
  //     if (!option.multiple && option.checked) {
  //       resOption.push({
  //         value: option.value,
  //         label: option.label,
  //       });
  //       return;
  //     }
  //     if (option.multiple && option.checked) {
  //       resOption.push({
  //         value: option.value,
  //         label: option.label,
  //       });
  //     }
  //   })
  //   // 没有选择数据时？
  //   onSelectFinally({
  //     valueFinally: resOption.reduce((sum,curr) => {return sum.value + curr.value}),
  //     labelFinally: resOption.map((item,_) => {return item.label}).join('/'),
  //   });

  // });


  const onChoose = (clickOption) => { // 全部结束告诉上层组件
    // TODO:至少选择一个？

    if (clickOption.multiple) { // 多选 - 单选全都不选中；多选选中
      // 选择多选时，单选变成不选中
      const newOptions = optionPerms.map((item) => {
        if (item.value === clickOption.value) return clickOption;
        if (!item.multiple) {
          item.checked = false;
        }
        return item;
      })

      setOptionPerms(newOptions); // 控制当前组件状态

      const checkedOptions = newOptions.filter((item) => item.checked);

      const value = checkedOptions.reduce((sum, curr) => sum + curr.value, 0);

      const label = checkedOptions.map((item) => item.label).join('/');

      onSelectFinally({
        valueFinally: value,
        labelFinally: label,
      });
    } else { // 单选
      onSelectFinally({
        valueFinally: clickOption.value,
        labelFinally: clickOption.label,
      });
      onVisibleChange(false);
    }
  }

  let multiIndex = null;

  return (
    <div
      className={cx('cooper-option-wrap')}
      ref={optionRef}>
      {
        optionPerms.map((option, index) => {
          if (!option.multiple && multiIndex === null) {
            multiIndex = index;
            return <>
              <div className={cx('line-multi')} />
              <PermOptionItem
                key={option.value}
                optionItem={option}
                onOptionClickCallback={onChoose}
                needBorder={multiIndex === index}
              />
            </>
          }

          return <PermOptionItem
            key={option.value}
            optionItem={option}
            onOptionClickCallback={onChoose}
          />
        })
      }
    </div>
  );
};


export default CooperOption;
