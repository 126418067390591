import { intl } from 'di18n-react';
import { useState, useEffect, useContext, useMemo } from 'react';
import classnames from 'classnames/bind';
import styles from './style.module.less';
import * as service from '@/service/knowledge/page';
import TextOnlyModal from '@/components/TextOnlyModal';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import TransferOwner from '@/components/serviceComponents/OperateFn/TransferOwner';
import ApplyHigherPerm from '@/baseComponents/ApplyHigherPerm';
import { useSelector } from 'react-redux';
import { openNewWindow } from '@/utils/index';

const cls = classnames.bind(styles);

const CHANGE_OWNER_TYPE = {
  NONE: 0,
  TRANSFER: 1,
  APPLY: 2,
};
const ChangePageOwnerEntry = ({ onVisibleChange }) => {
  const [changeOwnerType, setChangeOwnerType] = useState(0);
  const { pageId, knowledgeId } = useContext(LayoutContext);
  const { docInfo } = useSelector((state) => state.pageDetail);
  const { currentUserOrgMemberId: pageOwnerId, creatorMemberId } = docInfo;
  const [visible, setVisible] = useState(false);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [data, setData] = useState({})

  useEffect(async () => {
    const res = await service.getChangeOwnerType({
      type: 1, // 知识库0 页面1
      id: pageId,
    });
    setChangeOwnerType(res?.type);
  }, []);


  const modalData = useMemo(() => {
    if (!Object.keys(data).length) return {};
    return {
      10001: {
        title: intl.t('无法重复提交申请'),
        cancelText: intl.t('知道了'),
        okText: intl.t('查看详情'),
        handleOk: () => {
          openNewWindow(data?.bpmUrl)
        },
        tip: <span>
          {intl.t('你已提交过申请，由')}
          <a
            style={{ color: '#127FF0' }}
            href={`dchat://im/start_conversation?name=${data?.approver?.ldap}`}
          >
            {data?.approver?.cnName}
          </a>{intl.t('进行审批。请等待审批结果')}
        </span>,
      },
      10002: {
        title: intl.t('无法提交申请'),
        cancelText: intl.t('关闭'),
        okText: intl.t('联系申请者'),
        handleOk: () => {
          openNewWindow(`dchat://im/start_conversation?name=${data?.applicant?.ldap}`)
        },
        tip: <span>
          <a
            style={{ color: '#127FF0' }}
            href={`dchat://im/start_conversation?name=${data?.applicant?.ldap}`}
          >
            {data?.applicant?.cnName}
          </a> {intl.t('已提交所有权申请，你暂时无法提交申请')}
        </span>
        ,
      },
    }[data?.code]
  }, [data])


  const handleClick = async (e) => {
    e.stopPropagation();
    if (changeOwnerType === CHANGE_OWNER_TYPE.TRANSFER) {
      setVisible(true);
      window.__OmegaEvent('ep_dkpc_pagedetail_transferownship_ck');
      return;
    }
    // window.__OmegaEvent('ep_dkpc_pagedetail_applyownship_ck');
    try {
      const res = await service.pageVerify(pageId);
      const { code } = res;
      if (code === 10000) {
        setVisible(true);
        return;
      }
      setVerifyModalVisible(true);
      setData(res)
    } catch (error) { console.log(error) }
  };

  const onClose = () => {
    setVisible(false);
  };
  // const handleOpen = () => {
  //   openNewWindow(bpmUrl);
  //   onVisibleChange(false);
  // };

  const onTransferCallback = () => {
    setVisible(false);
    onVisibleChange(false);
    window.__OmegaEvent('ep_dkpc_pagedetail_transferownship_confirm_ck');
  };
  const handleVerify = () => {
    setVerifyModalVisible(false);
    onVisibleChange(false);
  };


  const onApplyCallback = () => {
    setVisible(false);
    onVisibleChange(false);
  };


  const onTransfer = ({ forTranslateOrgId }) => {
    return service.transferPageOwner({
      spaceId: knowledgeId,
      resourceId: pageId,
      resourceOwnerId: pageOwnerId, // 当前操作者
      forTranslateOrgId,
    });
  };

  const RenderEntryContent = ({
    onClick = () => { },
  }) => <div
    className={cls('change-page-owner')}
    onClick={onClick}>
    <i className={cls('dk-icon-shenqingyemianquanxian', 'dk-iconfont', 'owner-change-icon')} />
    <span className={cls('owner-change-text')}>{changeOwnerType === CHANGE_OWNER_TYPE.TRANSFER ? intl.t('转让页面所有权') : intl.t('申请更多权限')}</span>
  </div>

  return (
    <div className={cls('change-page-owner-wrap')}>
      {
        changeOwnerType === CHANGE_OWNER_TYPE.TRANSFER
        && <RenderEntryContent onClick={handleClick} />
      }
      {
        changeOwnerType === CHANGE_OWNER_TYPE.TRANSFER
          ? <TransferOwner
              visible={visible}
              pageOwnerId={creatorMemberId} // 页面所有者
              onClose={onClose}
              onTransferCallback={onTransferCallback}
              transferRequest={onTransfer}
          />
          : <ApplyHigherPerm
              pageId={pageId}
              isDk={true}
            >
            <RenderEntryContent />
          </ApplyHigherPerm>
      }

      {/* {
        changeOwnerType === CHANGE_OWNER_TYPE.APPLY
        && <ApplyOwner
          visible={visible}
          onClose={onClose}
          onApplyCallback={onApplyCallback}
        />
      } */}
      <TextOnlyModal
        customClass={cls('departMentModal')}
        handleCancel={handleVerify}
        modalVisible={verifyModalVisible}
        {...modalData}
      />
    </div>
  );
};

export default ChangePageOwnerEntry;
