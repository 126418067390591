import classBind from 'classnames/bind';
import { intl } from 'di18n-react';
import styles from './style.module.less';
import { Tooltip } from 'antd';


const cx = classBind.bind(styles);


const PermOptionItem = ({ optionItem, onOptionClickCallback, canApply }) => {
  // 多次点击：多选点击可以取消，单选点击不可以取消
  const onOptionClick = (option) => {
    let newOptions = { ...option };
    if (option.multiple) {
      newOptions.checked = !option.checked
    } else {
      newOptions.checked = true;
    }
    onOptionClickCallback(newOptions);
  }


  return (
    <Tooltip
      title={canApply ? '' : intl.t('仅支持申请更多权限，不可降级申请')}
      >
      <div
        key={optionItem.value}
        onClick={() => canApply && onOptionClick(optionItem)}
        className={cx('perm-option-item', {
          disabled: !canApply,
        })} >
        <div>
          <div className={cx('perm-option-label')}>{optionItem.label}</div>
          {
            optionItem.desc
            && <div className={cx('perm-option-desc')}>{optionItem.desc}</div>
          }
        </div>
        {optionItem.checked && <i
          className={cx('dropdown-checkbox__ico', {
            'dropdown-checkbox-disable__ico': !canApply,
          })} />}
      </div>

    </Tooltip>
  );
};

export default PermOptionItem;
