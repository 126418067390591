import NoData from '@/assets/icon/empty4.png';
import ErrorTips from '@/components/ErrorTips';
import NoMore from '@/components/NoMore';
import OperateMenu from '@/components/OperateMenu';
import FileTableSkeleton from '@/components/SkeletonPage/common/FileTableSkeleton';
import SpinRender from '@/components/SpinRender';
import cooperConfirm from '@/components/common/CooperConfirm';
import FileEllipsis from '@/components/common/FileEllipsis';
import ImageEnlarger from '@/components/common/ImageEnlarger';
import PathEllipsis from '@/components/common/PathEllipsis';
import { FlowChart, PERSONAL_TEAM } from '@/constants/cooperConstants';
import { getPersonalTrashList } from '@/service/cooper/personTrash';
import {
  checkParentExist,
  getSpaceTrashList,
  removeFromTrash,
  restoreFromTrash,
} from '@/service/cooper/teamTrash';
import { TEAM_ADMIN, TEAM_OWNER, setImgUrl } from '@/utils/cooperutils';
import { formatRecentTime } from '@/utils/index';
import { Checkbox, message } from 'antd';
import classBind from 'classnames/bind';
import { getLocale, intl } from 'di18n-react';
import { cloneDeep, debounce } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BatchOperate from '../BatchOperate/index';
import styles from './style.module.less';
import GetHtml from '@/utils/DOMPurify';
import {
  findChecks,
  getParentCheckStatus,
  treeToList,
  unselectAll,
} from './utils';

const cx = classBind.bind(styles);

function FoldTree({
  role,
  isTeam,
  teamId,
  viewType,
  originFileType,
  folderId,
  sortBy,
  orderAsc,
}) {
  const { needTrashSkeleton } = useSelector((s) => s.GlobalData);
  const { setNeedTrashSkeleton } = useDispatch().GlobalData;
  const { trashFileList } = useSelector((s) => s.CooperTrash);
  const { setTrashFileList } = useDispatch().CooperTrash;

  const [fileList, setFileList] = useState([]);
  const [, setUpdate] = useState(0);
  const [state, setState] = useState({
    root: {
      teamId,
      id: folderId,
      isRoot: true,
      level: 0,
      checkStatus: 0,
      children: [],
    },
    pageOption: {
      pageSize: 100,
      sortBy,
      orderAsc,
      pageNum: 0,
    },
    isEnd: false,
    batchMode: false,
    nameWidth: 0,
    initialWidth: 0,
    originalWidth: 0,
    openedDir: [],
    isDraggingResizer: false,
    highlightItem: null,
    showNoAuthModal: false,
    fileOpreationType,
    loading: true,
  });

  const { root, isEnd, batchMode, nameWidth, fileOpreationType } = state;

  const nameRef = useRef(null);
  let checks = [];

  const isListView = () => {
    return viewType === 'LIST';
  };

  const calculateHasMore = (data) => {
    const { currentPage, pageSize, totalCount } = data;
    const more = (currentPage + 1) * pageSize < totalCount;
    return more;
  };

  const initFile = async () => {
    const rootCurr = {
      teamId,
      id: folderId,
      isRoot: true,
      level: 0,
      checkStatus: 0,
      children: [],
    };

    let data;

    if (isTeam) {
      data = await getSpaceTrashList(teamId);
      setNeedTrashSkeleton(false);
    } else {
      data = await getPersonalTrashList();
      setNeedTrashSkeleton(false);
    }

    data?.items?.length > 0
      && rootCurr.children.push(
        ...data?.items.map((ch) => ({
          ...ch,
          level: 1,
          // parent: rootCurr,
        })),
      );

    const pageOption = {
      pageSize: 100,
      sortBy,
      orderAsc,
      pageNum: !calculateHasMore(data) ? 0 : 1,
    };

    setState({
      ...state,
      root: rootCurr,
      pageOption,
      isEnd: !calculateHasMore(data),
      nameWidth,
      loading: false,
      // initialWidth: nameWidth,
    });

    const files = treeToList(rootCurr, isListView());
    if (isTeam) {
      setFileList(files);
    } else {
      setTrashFileList(files);
    }
  };

  const toggleOpen = async () => {
    // let { openedDir, pageOption } = state;
    // // 加载子节点
    // if (item.hasChild && !item.isOpen) {
    //   openedDir.push(item);
    //   setState({ ...state, openedDir });
    //   const children = await getChildFiles(item.id, teamId, pageOption);
    //   if (children.length > 0) {
    //     item.children = children.map((ch) => ({
    //       ...ch,
    //       level: item.level + 1,
    //       parent: item,
    //       checkStatus: item.checkStatus === 1 ? 1 : 0,
    //     }));
    //   } else {
    //     item.hasChild = false;
    //   }
    // } else if (item.isOpen) {
    //   const index = openedDir.findIndex((cur) => item.id === cur.id);
    //   openedDir.splice(index, 1);
    //   setState({ ...state, openedDir });
    // }
    // // 更新
    // item.isOpen = !item.isOpen;
    // const files = treeToList(state.root, isListView())
    // setFileList(files)
  };
  const setBatchMode = (batchModeCurr) => {
    // 取消所有勾选
    if (!batchModeCurr) {
      const { root } = state;
      unselectAll(root);
    } // setIsShowSwitchView(!batchMode);
    setState({ ...state, batchMode: batchModeCurr });
  };
  const toggleCheck = (item) => {
    // 更新自己
    const checkStatus = item.checkStatus === 1 ? 0 : 1;
    item.checkStatus = item.checkStatus === 1 ? 0 : 1; // 更新父节点
    let p = item;
    while (p.parent) {
      p = p.parent;
      p.checkStatus = getParentCheckStatus(p);
    } // 更新子节点
    function _toggleCheck(_item) {
      if (_item !== item) {
        _item.checkStatus = checkStatus;
      }
      (_item.children || []).forEach((ch) => _toggleCheck(ch));
    }

    _toggleCheck(item);
    // 处理批量时的视图切换问题，触发渲染
    const { root } = state;
    const checksCurr = findChecks(root, isListView());
    if (isListView()) {
      setBatchMode(checksCurr.length > 0);
    } else {
      setUpdate((prev) => prev + 1);
    }
  };

  const _refreshRootNode = async () => {
    // const { root: item, pageOption } = state;
    // const { pageSize: pSize, sortBy, orderAsc } = pageOption;
    // const pageSize = Math.max(pSize, item.children.length);
    // const getRootFiles = createGetFiles(folderId, teamId, pageSize, sortBy, orderAsc);
    // const { result, isEnd } = await getRootFiles();
    // _mergeWithState(result, item);
  };
  const _mergeWithState = (newList, parent) => {
    newList?.forEach((ch) => {
      ch.level = parent.level + 1;
      // ch.parent = parent;
    });

    newList.forEach((item, idx) => {
      const old = (parent.children || []).find((it) => it.id === item.id);
      if (old) {
        // 保持引用不变
        newList[idx] = cloneDeep(old, item, {
          checkStatus: old.checkStatus,
          isOpen: old.isOpen,
          hasChild: old.hasChild,
          children: old.children,
        });
      } else {
        parent.children.push(item);
      }
    });

    parent.hasChild = newList.length > 0;
    parent.checkStatus = getParentCheckStatus(parent);

    const files = treeToList(parent, isListView());
    if (isTeam) {
      setFileList(files);
    } else {
      setTrashFileList(files);
    }
    // setTrashFileList(files);
  };

  const batchRefreshWithState = async (checksCurr) => {
    await baseBatchRefreshWithState(checksCurr);

    refreshOpened(checksCurr);
  };

  const baseBatchRefreshWithState = async (checksCurr) => {
    checksCurr.forEach((item) => refreshWithState(item));
    setBatchMode(false);
    // 再刷新根节点
    await _refreshRootNode();
  };

  const refreshWithState = async (item) => {
    if (item.isRoot) {
      return _refreshRootNode();
    }
    return _refreshChildNode(item.parent);
  };

  const _refreshChildNode = async (item) => {
    // const { pageOption } = state;
    // const result = await getChildFiles(item.id, teamId, pageOption);
    // _mergeWithState(result, item);
    // setUpdate((prev) => prev + 1)
  };
  const clickFile = () => {
    // if (state.batchMode === true) {
    //   toggleCheck(item);
    //   return;
    // }
    // handleFileClick(item, teamId, originFileType);
  };
  const operateCallback = async (item) => {
    // 拿不到目标位置，直接刷新本页面
    // 先刷新操作节点父级
    await refreshWithState(item); // 再刷新根节点
    await _refreshRootNode();
  };

  const setBatchModeOpen = (item) => {
    item.checkStatus = 1;
    item.parent.checkStatus = 2;
    setBatchMode(true);
  };

  const refreshOpened = (operateItem) => {
    // 刷新所有open的节点
    const { openedDir } = state;

    openedDir.forEach((item) => {
      const index = operateItem.findIndex((ope) => ope.id === item.id);
      if (index === -1) {
        _refreshChildNode(item);
      }
    });
  };

  const handleScroll = debounce((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight + 25 >= scrollHeight) {
      if (!state.isEnd) {
        loadMore();
      }
    }
  }, 400);

  const loadMore = debounce(
    async () => {
      const { root: item } = state;
      let data;

      if (isTeam) {
        data = await getSpaceTrashList(teamId, state.pageOption.pageNum);
      } else {
        data = await getPersonalTrashList(state.pageOption.pageNum);
      }

      _mergeWithState(data.items, item);
      setState({
        ...state,
        pageOption: {
          ...state.pageOption,
          pageNum: !calculateHasMore(data)
            ? state.pageOption.pageNum
            : state.pageOption.pageNum + 1,
        },
        isEnd: !calculateHasMore(data),
      });

      // const { result, isEnd } = await getRootFiles();
    },
    1000,
    { leading: true },
  );

  useEffect(() => {
    initFile();
  }, [teamId]);

  const gotoDetail = useCallback(
    (item) => {
      let url = '';

      switch (item.resourceType) {
        case 'FILE':
          if (isTeam) {
            url = item.parentId === 0
              ? `/team-file/${item.spaceId}`
              : `/team-file/${item.spaceId}/${item.parentId}`;
          } else {
            url = item.parentId === 0 ? '/disk' : `/files/${item.parentId}`;
          }
          break;
        case 'DIR':
          if (isTeam) {
            url = `/team-file/${item.spaceId}/${item.resourceId}`;
          } else {
            url = `/files/${item.resourceId}`;
          }
          break;
        case 'COOPER_DOC':
        case 'COOPER_ANONYMOUS_DOC':
        case 'SHIMO2_WORD':
          url = `/docs/document/${item.resourceId}`;
          break;
        case 'COOPER_SHEET':
        case 'SHIMO2_EXCEL':
          url = `/docs/sheet/${item.resourceId}`;
          break;
        case 'COOPER_SLICE':
        case 'SHIMO2_PPT':
          url = `/docs/slide/${item.resourceId}`;
          break;
        case 'DK_PAGE':
          url = `/knowledge/${item.spaceId}/${item.resourceId}`;
          break;
        case 'DK_FILE':
          url = `/knowledge/${item.spaceId}/${item.resourceId}`;
          break;
        default:
          url = !isTeam ? '/disk' : `/team-file/${item.spaceId}}`;
          break;
      }

      window.open(url);
    },
    [isTeam],
  );

  const config = {
    multiSelectOperate: viewType === 'TREE',
    permanentlyDeleteOperate:
      role === TEAM_OWNER || role === TEAM_ADMIN || !isTeam,
  };

  checks = findChecks(root, isListView()).filter(
    (checkboxItem) => checkboxItem.type !== FlowChart && checkboxItem.mime_type !== 9,
  );

  const restore = async (item) => {
    let text;
    let isExist;
    window.__OmegaEvent('ep_trash_restore_ck', '', {
      platform: 'new',
    });
    try {
      isExist = await checkParentExist(
        item[0].spaceId,
        item[0].id,
        item[0].appId,
      );
      text = isExist
        ? intl.t('恢复内容将展示在历史所在位置，确认恢复内容？')
        : intl.t(
          '由于恢复内容父目录不存在，恢复内容后将移动至根目录，确认恢复内容？',
        );
    } catch (error) {
      if (error.errorCode === 1149 || error.errorCode === 1150) {
        message.error(
          <span>
            {error.message}，
            <span
              className={cx('message-link')}
              onClick={() => {
                window.location.reload();
              }}
            >
              {intl.t('请刷新列表')}
            </span>
          </span>,
        );
      } else {
        message.error(error.message);
      }
      return;
    }

    cooperConfirm({
      title: intl.t('确认恢复内容？'),
      icon: null,
      content: text,
      okText: intl.t('确认恢复'),
      cancelText: intl.t('取消'),
      className: cx('confirm-modal'),
      closable: true,
      closeIcon: (
        <i
          className={cx('dk-iconfont', 'dk-icon-guanbi', 'confirm-modal-close')}
        />
      ),
      autoFocusButton: null,
      onOk() {
        restoreFromTrash(
          item[0].spaceId,
          item[0].id,
          !isExist,
          item[0].appId,
        ).then((res) => {
          if (res.rename) {
            message.success(
              <span>
                {intl.t('恢复成功，当前已重名，名称后自动加(1)，')}

                <span
                  className={cx('message-link')}
                  onClick={() => {
                    gotoDetail(res);
                  }}
                >
                  {intl.t('前往查看')}
                </span>
              </span>,
            );
            return;
          }
          message.success(
            <span>
              {intl.t('恢复成功，')}

              <span
                className={cx('message-link')}
                onClick={() => {
                  gotoDetail(res);
                }}
              >
                {intl.t('前往查看')}
              </span>
            </span>,
          );

          setTimeout(() => {
            initFile();
          }, 400);
        });
      },
    });
  };

  const remove = (item) => {
    window.__OmegaEvent('ep_trash_deleteforever_ck', '', {
      platform: 'new',
    });
    cooperConfirm({
      title: intl.t('确认彻底删除内容？'),
      icon: null,
      content: intl.t('删除后内容不支持找回，确认彻底删除内容？'),
      okText: intl.t('确认删除'),
      cancelText: intl.t('取消'),
      className: cx('confirm-modal', 'delete-modal'),
      closable: true,
      closeIcon: (
        <i
          className={cx('dk-iconfont', 'dk-icon-guanbi', 'confirm-modal-close')}
        />
      ),
      autoFocusButton: null,
      onOk() {
        removeFromTrash(item[0].spaceId, item[0].id, item[0].appId)
          .then(() => {
            message.success(intl.t('删除成功'));
            setTimeout(() => {
              initFile();
            }, 400);
          })
          .catch((error) => {
            if (error.errorCode === 1149 || error.errorCode === 1150) {
              message.error(
                <span>
                  {error.message}，
                  <span
                    className={cx('message-link')}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    {intl.t('请刷新列表')}
                  </span>
                </span>,
              );
            } else {
              message.error(error.message);
            }
          });
      },
    });
  };

  const trashHandlers = {
    restore,
    delete: remove,
  };

  return (
    <>
      {
        needTrashSkeleton && !isTeam ? (
          <>
            <div
              style={{ padding: '8px 0 0 0px' }}
              dangerouslySetInnerHTML={{ __html: GetHtml(FileTableSkeleton) }} />
          </>
        ) : (
          <div className={cx({ 'folder-tree': true, 'list-view': isListView(), 'batch-mode': batchMode })} >
            <div className={cx('tb-header-container')}>
              <div className={cx('tb-header')}>
                <span
                  className={cx('file-name')}
                  ref={nameRef}
                  style={nameWidth > 0 ? { width: `${nameWidth}px` } : {}}
                >
                  {(batchMode || viewType === 'LIST') && (
                    <Checkbox
                      indeterminate={root.checkStatus === 2}
                      checked={root.checkStatus === 1}
                      onChange={() => toggleCheck(root)}
                    />
                  )}

                  <span>
                    {checks.length > 0
                      ? intl.t('已选中{one}项', {
                        one: `${checks.length}`,
                      })
                      : intl.t('名称')}
                  </span>
                </span>
                <span className={cx('file-owner')}>{intl.t('操作者')}</span>
                <span className={cx('file-time')}>{intl.t('操作时间')}</span>
                <span className={cx('file-time')}>{intl.t('剩余时间')}</span>
                <span className={cx('file-operate')}>{intl.t('操作')}</span>
              </div>
            </div>

            <div
              className={cx('tb-body', 'os-scrollbar')}
              onScroll={handleScroll}>
              {(isTeam ? fileList : trashFileList).map((item, index) => {
                return (
                  <li
                    key={index}
                    className={cx('tb-body-row')}>
                    <span
                      className={cx('file-name')}
                      style={{
                        paddingLeft: Math.min(
                          (item.level - 1) * 40,
                          Math.max(nameWidth, 300),
                        ),
                        width: nameWidth || '',
                      }}
                    >
                      {(batchMode || viewType === 'LIST') && (
                        <Checkbox
                          disabled={item.type === FlowChart && item.mime_type === 9}
                          indeterminate={item.checkStatus === 2}
                          checked={item.checkStatus === 1}
                          onChange={() => toggleCheck(item)}
                        />
                      )}

                      {!isListView() && batchMode && (
                        <div
                          onClick={() => toggleOpen(item)}
                          className={cx('ft-triangle-wrapper')}
                        >
                          <i
                            className={cx({
                              triangle: true,
                              'triangle-li': true,
                              'is-open': item.isOpen,
                            })}
                            style={{ opacity: item.hasChild ? 1 : 0 }}
                          />
                        </div>
                      )}

                      <div
                        onClick={() => clickFile(item)}
                        className={cx('file-name-display')}
                      >
                        <ImageEnlarger
                          src={setImgUrl(item)}
                          isTiny={!!item.tiny}
                          mimeType={item.mime_type || item.mimeType}
                          resourceType={item.resourceType}
                        />

                        <span className={cx('file-name-display-text')}>
                          <FileEllipsis
                            value={item?.resourceName}
                            isShowStar={false}
                            doneCallback={() => _refreshChildNode(item.parent)}
                            record={item}
                            originFileType={originFileType}
                            notClick={true}
                          />

                          {item.resourceType === 'DIR' && (
                            <div className={cx('file-name-display-count')}>
                              {intl.t('包含{slot0}个文件，{slot1}个文件夹', {
                                slot0: item.fileCount,
                                slot1: item.dirCount,
                              })}
                            </div>
                          )}

                          {(item.resourceType === 'DK_PAGE'
                            || item.resourceType === 'DK_FILE') && (
                              <div className={cx('file-name-display-count')}>
                                {intl.t('包含{slot0}个子页面', {
                                  slot0: item.fileCount,
                                })}
                              </div>
                          )}
                        </span>
                      </div>
                    </span>
                    <div className={cx('file-owner')}>
                      {
                        getLocale() === 'en-US'
                          ? <PathEllipsis value={item?.deletedByName?.englishName} />
                          : <PathEllipsis value={item?.deletedByName?.chineseName} />
                      }
                    </div>
                    <div className={cx('file-time')}>
                      <PathEllipsis
                        value={formatRecentTime(
                          new Date(item?.deletedTime).getTime(),
                        )}
                      />
                    </div>
                    <div
                      className={cx('file-time')}
                      style={
                        parseInt(item?.leftTime, 10) <= 3 * 24 ? { color: 'red' } : {}
                      }
                    >
                      <PathEllipsis
                        value={
                          intl.t('{slot0}天{slot1}小时', {
                            slot0: parseInt(item?.leftTime / 24, 10),
                            slot1: item?.leftTime % 24,
                          })
                        }
                      />
                    </div>
                    {!batchMode && (
                      <span className={cx('file-operate')}>
                        <OperateMenu
                          key={Date.now()}
                          isTrash={true}
                          file={item}
                          isTeam={isTeam}
                          config={config}
                          fileOpreationType={fileOpreationType}
                          doneCallback={() => operateCallback(item)}
                          originFileType={PERSONAL_TEAM}
                          setBatchMode={() => setBatchModeOpen(item)}
                          isFlowChart={
                            item.type === FlowChart && item.mime_type === 9
                          }
                          uniqueCallback={{
                            moveOperate: () => refreshOpened([item]),
                            copyOperate: () => refreshOpened([item]),
                          }}
                          trashHandlers={trashHandlers}
                        />
                      </span>
                    )}
                  </li>
                );
              })}

              {/* {!state.loading && fileList.length !== 0 && ( */}
              <li className={cx('bottom-tip')}>
                {isEnd ? (
                  (isTeam ? fileList : trashFileList).length !== 0 && <NoMore />
                ) : (
                  <SpinRender loading={true} />
                )}
              </li>
              {/* )} */}
            </div>
            {!state.loading && !(isTeam ? fileList : trashFileList).length && (
              <ErrorTips
                title={intl.t('暂无数据')}
                img={NoData} />
            )}

            {(batchMode || checks.length > 0) && (
              <BatchOperate
                checks={checks}
                doneCallback={() => batchRefreshWithState(checks)}
                deleteCallback={() => baseBatchRefreshWithState(checks)}
              >
                {/* <Button
              className={cx('batch-cancel')}
              onClick={() => setBatchMode(false)}
              >
              {intl.t(' 取消')}
              </Button> */}
              </BatchOperate>
            )}
          </div>
        )
      }
    </>
  );
}

export default FoldTree;
