import classNames from 'classnames/bind';
import { Modal, Button, Input, message } from 'antd';
import { intl } from 'di18n-react';
import ChooseMemberPerm from '@/baseComponents/ChooseMemberPerm';
import { useSelector } from 'react-redux';
import { DkOptionDefault } from '@/constants/userPerm';
import { applyHigherPermFn, applyOwnerPermFn, applyOwnerPermCheck } from '@/service/knowledge/permission';
import styles from './style.module.less';
import { openNewWindow } from '@/utils';
import cooperConfirm from '@/components/common/CooperConfirm';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';
import { useState, useMemo } from 'react';

const { TextArea } = Input;

const cx = classNames.bind(styles);

const OWNER_PERM = 'PAGE_OWNER';
const ADMIN_PERM = 'PAGE_ADMIN';

const ApplyPermModal = ({
  onClose,
  roleKey,
  perm,
  isDk,
  isGlobalPerm,
  pageId,
}) => {
  const [choosedData, setChoosedData] = useState(null);
  const [reason, setReason] = useState();
  const { globalOutsideChain } = useSelector((state) => state.CooperIndex);
  const notification = useNotification();

  const roleExitMap = () => {
    return {
      read: intl.t('查看'),
      edit: intl.t('编辑'),
      download: intl.t('下载'),
      3: intl.t('成员'),
      4: intl.t('管理员'),
      5: intl.t('所有者'),
    }
  }
  const getExitRole = (permCurr) => {
    if (Number.isNaN(Number(permCurr))) {
      const newPerm = `read,${permCurr}`
      const formateArr = newPerm.split(',').map((item) => roleExitMap()[item]);
      return formateArr.join('/');
    }
    return roleExitMap()[permCurr];
  }

  const checkOwner = () => {
    return applyOwnerPermCheck({ resourceId: pageId }).then((res) => {
      const { code, approver = {}, applicant = {}, bpmUrl } = res;
      const { ldap, cnName } = approver;
      if (code === 10002) { // 别人提交了所有权
        cooperConfirm({
          title: intl.t('无法提交申请'),
          cancelText: intl.t('关闭'),
          okText: intl.t('联系申请者'),
          onOk: () => {
            openNewWindow(`dchat://im/start_conversation?name=${applicant.ldap}`)
          },
          content: <span>
            <a
              style={{ color: '#127FF0' }}
              href={`dchat://im/start_conversation?name=${applicant.ldap}`}
          >
              {applicant.cnName}
            </a> {intl.t('已提交所有权申请，你暂时无法提交申请')}
          </span>,
        })
        onClose();
      } else if (code === 10001) { // 自己申请重复
        cooperConfirm({
          title: intl.t('无法重复提交申请'),
          cancelText: intl.t('知道了'),
          okText: intl.t('查看详情'),
          onOk: () => {
            openNewWindow(bpmUrl)
          },
          content: <span>
            {intl.t('你已提交过所有权申请，由{slot0}进行审批。请等待审批结果', {
              slot0: <a
                style={{ color: '#127FF0' }}
                href={`dchat://im/start_conversation?name=${ldap}`}
              >
                {cnName}
              </a>,
            })}
          </span>,
        });
        onClose();
      }
      return code;
    })
  }

  const applyOwner = async () => {
    try {
      const resCode = await checkOwner();
      if (resCode !== 10000) return;
      applyOwnerPermFn({
        resourceId: pageId,
        reason,
      }).then((res) => {
        const { approver = {}, bpmUrl, code } = res;
        if (code === 201034) { // 外部租户，暂无审批人
          notification(NotificationStatus.ERROR, intl.t('暂无审批人，可联系相关滴滴员工提交申请'));
        } else {
          cooperConfirm({
            title: intl.t('申请已提交'),
            content: <span>
              {intl.t('你的申请已提交，将由{slot0}进行审批。审批完成后，你将会收到消息通知', {
                slot0: <a
                  style={{ color: '#127FF0' }}
                  href={`dchat://im/start_conversation?name=${approver.ldap}`}
                >
                  {approver.cnName}
                </a>,
              })}
            </span>,
            onOk: () => {
              openNewWindow(bpmUrl);
            },
            okText: intl.t('查看详情'),
            cancelText: intl.t('知道了'),
          });
        }
        onClose();
      })
    } catch (err) {
      // TODO:接口对于无权限没有处理
      if (err.errorCode === 100005 && err.message === '资源不存在') {
        notification(NotificationStatus.ERROR, intl.t('页面已被删除，3s后为你自动刷新页面'));
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        notification(NotificationStatus.ERROR, err.errorMessage);
      }
    }
  }

  const applyOther = ({ isReApply }) => {
    applyHigherPermFn({
      adminPerm: choosedData.value,
      message: reason,
      resourceId: pageId,
      isReApply: isReApply ?? false,
    }).then((res) => {
      const { code, perm: permPre } = res;
      if (code === 200) {
        notification(NotificationStatus.SUCCESS, intl.t('您的申请已提交'));
        onClose();
      } else if (code === 200001) { // 自己申请过所有者，又申请其他权限
        cooperConfirm({
          title: intl.t('是否提交新申请?'),
          zIndex: 2001,
          content: intl.t('您已经申请了页面的{prePerm}权限，目前还在审批中。是否取消原先申请流程，重新提交{nextPrem}权限的申请？', {
            prePerm: intl.t('所有者'),
            nextPrem: choosedData.text,
          }),
          onOk: () => onApply({ isReApply: true }),
          okText: intl.t('确认'),
          cancelText: intl.t('取消'),
          className: 'double-confirmation',
          getContainer: () => document.querySelector('.base-service-modal'),
        });
      } else if (code === 200003) { // 自己申请过其他权限，再申请其他权限（除所有者之外）
        cooperConfirm({
          title: intl.t('是否提交新申请?'),
          zIndex: 2001,
          content: intl.t('您已经申请了页面的{prePerm}权限，目前还在审批中。是否取消原先申请流程，重新提交{nextPrem}权限的申请？', {
            prePerm: getExitRole(permPre),
            nextPrem: choosedData.text,
          }),
          onOk: () => onApply({ isReApply: true }),
          okText: intl.t('确认'),
          cancelText: intl.t('取消'),
          className: 'double-confirmation',
          getContainer: () => document.querySelector('.base-service-modal'),
        });
      } else if (code === 200004) { // 暂无审批人
        if (choosedData.value === ADMIN_PERM) {
          notification(NotificationStatus.ERROR, intl.t('暂无审批人,建议申请页面所有权'));
        } else {
          notification(NotificationStatus.ERROR, <p className={cx('success-tip')}>
            {intl.t('暂无审批人，{slot0}', {
              slot0: <a
                style={{ color: '#127FF0' }}
                href={globalOutsideChain?.no_approver_solution_dk}
                target='_blank'
              >
                {intl.t('查看其他解决方案')}
              </a>,
            })}
            <i
              className={cx('dk-iconfont', 'dk-icon-guanbi', 'icon-close')}
              onClick={() => {
                message.destroy();
              }}
      />
          </p>, () => {
            message.destroy();
          }, 0);
          onClose();
        }
      } else if (code === 200005) { // 文档被删除
        notification(NotificationStatus.ERROR, intl.t('页面已被删除，3s后为你自动刷新页面'));
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else if (code === 200006) { // 文档无权限 - 待测
        notification(NotificationStatus.ERROR, intl.t('您无法访问该页面，不能申请更多权限'));
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
    }).catch((error) => {
      const { errorCode, errorMessage } = error;
      if (errorCode === 300002 || (errorCode === 100001 && errorMessage === '资源不存在')) {
        notification(NotificationStatus.ERROR, intl.t('页面已被删除，3s后为你自动刷新页面'));
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        notification(NotificationStatus.ERROR, errorMessage);
      }
    })
  }


  const onApply = async ({ isReApply }) => {
    if (choosedData.value === OWNER_PERM) {
      applyOwner();
    } else {
      applyOther({ isReApply })
    }
  }


  const currRole = useMemo(() => {
    if (isDk) {
      return DkOptionDefault().filter((item) => item.value === roleKey)[0] ?? {}
    }
  }, [isDk, DkOptionDefault(), roleKey])


  return <Modal
    visible={true}
    maskClosable={false}
    destroyOnClose
    wrapClassName={'base-service-modal'}
    title={intl.t('申请更多权限')}
    footer={
      <div className={cx('footer-wrap')}>
        <a
          className={cx('explanation')}
          target='_blank'
          href={isDk ? globalOutsideChain?.approver_help_dk : globalOutsideChain?.approver_help}>{ intl.t('谁是审批人？')}</a>
        <div className={cx('btn-content')}>
          <Button
            onClick={onClose}>{intl.t('取消')}</Button>
          <Button
            className={cx('btn-blue')}
            style={{ marginLeft: 20 }}
            type="primary"
            onClick={onApply}
            disabled={!(choosedData?.value && reason)}
          >
            {intl.t('提交申请')}
          </Button>
        </div>
      </div>
    }
    onCancel={onClose}
  >
    <div className={cx('label-item')}>
      <p className={cx('apply-body-perm', 'label-text')}>
        {intl.t('你在当前{slot0}的权限为{slot1}，申请获得以下权限', {
          slot0: isDk ? intl.t('页面') : intl.t('文档'),
          slot1: <span className={cx('curr-perm')}>{currRole?.label}</span>,
        })}
        <span className={cx('require')}>*</span>
      </p>
      <ChooseMemberPerm
        isDk={isDk}
        currRole={currRole}
        perm={perm}
        applyedPerm={choosedData?.value}
        isGlobalPerm={isGlobalPerm}
        setChoosedData={(choosed) => { // 一个value 一个 text
          setChoosedData(choosed)
        }}
      />
    </div>

    <div className={cx('label-item')}>
      <p className={cx('apply-body-reason', 'label-text')}>
        {intl.t('申请原因')}
        <span className={cx('require')}>*</span>
      </p>
      <TextArea
        onChange={(e) => setReason(e.target.value)}
        placeholder={intl.t('必填，最多200字')}
        maxLength={200}
        className={cx('apply-input')}
        value={reason}
        autoSize={false}
        style={{
          height: 100,
          resize: 'none',
        }}
      />
    </div>
  </Modal>
}

export default ApplyPermModal;
