import classNames from 'classnames/bind';
import { intl } from 'di18n-react';
import { useState } from 'react';
import { Popover } from 'antd';
import DkOption from './DkOption';
import CooperOption from './CooperOption';
import styles from './style.module.less';

const cx = classNames.bind(styles);


const ChooseMemberPerm = ({ isDk, currRole, perm, applyedPerm, setChoosedData, isGlobalPerm }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [permText, setPermText] = useState(intl.t('请选择'));

  const onSelectFinally = ({ valueFinally, labelFinally }) => { // value && label
    setChoosedData({
      text: labelFinally ?? null,
      value: valueFinally ?? null,
    })
    setPermText(labelFinally || intl.t('请选择'))
  }

  const notSpaceMember = false;


  return <div
    className={cx('choose-member-perm', {
      active: popoverVisible,
    })}

    id='choose-member-perm-wrap'>
    <Popover
      content={isDk
        ? <DkOption
            onSelectFinally={onSelectFinally}
            onVisibleChange={(v) => { !v && setPopoverVisible(v); }}
            applyedPerm={applyedPerm}
            currRole={currRole}
            isGlobalPerm={isGlobalPerm}
      />
        : <CooperOption
            onSelectFinally={onSelectFinally}
            onVisibleChange={(v) => { !v && setPopoverVisible(v); }}
            applyedPerm={applyedPerm}
            perm={perm}
        />
      }
      visible={popoverVisible}
      trigger="click"
      placement={'bottomLeft'}
      align={{ offset: [-14, 12] }}
      onVisibleChange={(v) => {
        !v && setPopoverVisible(v);
      }}
      destroyTooltipOnHide={true}
      overlayClassName={cx('member-perm-popover', {
        'member-perm-popover-small': isDk || (!isDk && notSpaceMember),
      })}
      getPopupContainer={() => document.getElementById('choose-member-perm-wrap')}
    >
      <div
        className={cx('choosed-perm', {
          placeholder: permText === intl.t('请选择'),
        })}
        onClick={() => {
          setPopoverVisible(true);
        }}
      >
        <div className={cx('choosed-perm-text')}>{permText}</div>
        <i
          className={cx('dk-iconfont', 'dk-icon-jiantouyou', 'choosed-perm-icon', {
            'icon-up': popoverVisible,
          })} />
      </div>

    </Popover>
  </div>
}

export default ChooseMemberPerm;
