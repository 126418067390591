import { useState, useRef, useEffect, useMemo } from 'react';
import { Input, Popover } from 'antd';
import { DkOptionDefault } from '@/constants/userPerm';
import PermOptionItem from '../PermOptionItem';
import classBind from 'classnames/bind';
import styles from '../style.module.less';


const cx = classBind.bind(styles);

// 都是单选，简单处理
const DkOption = ({ currRole, onSelectFinally, applyedPerm, onVisibleChange, isGlobalPerm }) => {
  const optionRefDk = useRef(null);

  const formateDkOption = useMemo(() => {
    if (isGlobalPerm) {
      return DkOptionDefault().map((option, _) => {
        return {
          ...option,
          checked: option.value === applyedPerm,
        }
      })
    }
    // 非知识库内成员，只能选择只读成员/成员
    return DkOptionDefault().filter((option, _) => option.isGlobal).map((option, _) => {
      return {
        ...option,
        checked: option.value === applyedPerm,
      }
    })
  }, [isGlobalPerm, applyedPerm])


  const onChoose = (newOptions) => {
    onSelectFinally({
      valueFinally: newOptions.value,
      labelFinally: newOptions.label,
    })
    onVisibleChange(false);
  }

  return (
    <div
      className={cx('dk-option-wrap')}
      ref={optionRefDk}>
      {
        formateDkOption.map((option, _) => {
          return <PermOptionItem
            key={option.value}
            optionItem={option}
            onOptionClickCallback={onChoose}
            canApply={option.roleKeyFront > currRole.roleKeyFront}
          />
        })
      }
    </div>
  );
};

export default DkOption;
