import { intl } from 'di18n-react';

const EditorMicroUrlMap = {
  // dev: 'http://172.22.158.5:8080',
  dev: '//cooper-test.didichuxing.com/didoc_knowledge/',
  test: '/didoc_knowledge/',
  qa: '//cooper-qa.didichuxing.com/didoc_knowledge/',
  prod: 'https://cooper.didichuxing.com/m/didoceditor/',
};
const EditorMicroUrl = EditorMicroUrlMap[process.env.APP_ENV];
const EditorName = '_tenon_didoc_editor';
console.log('*** EditorMicroUrl', EditorMicroUrl, process.env.APP_ENV);
const SaveStatusText = {
  success: intl.t('保存成功'),
  failed: intl.t('保存失败'),
  'send-failed': intl.t('保存失败'),
  'consume-failed': intl.t('保存失败'),
  sending: intl.t('保存中...'),
};

// 是否可以查看详情 1可以 0 不可以
const CanShowDetail = 1;

const getIframeConfig = () => {
  return window.didocIframeABSwitch
    ? {
      iframe: {
        width: 28,
        text: 'iframe',
        icon: 'icon-iframe1',
        showTip: true,
        tooltip: 'iframe',
        macToolTip: 'iframe',
        label: 'Beta',
      },
    }
    : {};
};
// 这个文件不需要翻译
const MenuConfig = {
  floatingMenuContent: {},
  menuBarContent: {
    undo: {
      width: 28,
      text: '撤销',
      icon: 'icon-chexiao11',
      showTip: true,
      tooltip: '撤销 <br /> Ctrl Z',
      macToolTip: '撤销 <br /> ⌘Z',
    },
    redo: {
      width: 28,
      text: '重做',
      icon: 'icon-zhongzuo11',
      showTip: true,
      tooltip: '重做 <br /> Ctrl+Shift+Z',
      macToolTip: '重做 <br /> ⌘+Shift+Z',
    },
    formatPainter: {
      width: 28,
      text: '格式刷',
      icon: 'icon-geshishua1',
      showTip: true,
      tooltip: '格式刷',
      macToolTip: '格式刷',
      activeTooltip: '取消格式刷',
      activeMacToolTip: '取消格式刷',
    },
    formatBrush: {
      width: 28,
      text: '清除格式',
      icon: 'icon-qingchugeshi3',
      showTip: true,
      tooltip: '清除格式',
      macToolTip: '清除格式',
    },
    split_line1: {
      width: 17,
    },
    heading: {
      width: 60,
      text: '标题',
      showTip: true,
      tooltip: '标题 <br /> Control+Shift+Number',
      macToolTip: '标题 <br /> Control+Shift+Number',
    },
    fontsize: {
      width: 36,
      text: '字号',
      showTip: true,
      tooltip: '字号',
      macToolTip: '字号',
    },
    split_line2: {
      width: 17,
    },

    strong: {
      width: 28,
      text: '加粗',
      icon: 'icon-jiacu2',
      showTip: true,
      tooltip: '加粗 <br /> Ctrl B',
      macToolTip: '加粗 <br /> ⌘B',
    },
    em: {
      width: 28,
      text: '斜体',
      icon: 'icon-xieti1',
      showTip: true,
      tooltip: '斜体 <br /> Ctrl I',
      macToolTip: '斜体 <br /> ⌘I',
    },
    underline: {
      width: 28,
      text: '下划线',
      icon: 'icon-xiahuaxian11',
      showTip: true,
      tooltip: '下划线 <br/> Ctrl U',
      macToolTip: '下划线 <br/> ⌘U',
    },
    strikethrough: {
      width: 28,
      text: '删除线',
      icon: 'icon-henghuaxian1',
      showTip: true,
      tooltip: '删除线 <br/> Ctrl+Shift+X',
      macToolTip: '删除线 <br/> ⌘+Shift+X',
    },
    split_line3: {
      width: 17,
    },
    textColor: {
      width: 28,
      text: '文字颜色',
      icon: 'icon-wenziyanse3',
      showTip: true,
      tooltip: '文字颜色',
      macToolTip: '文字颜色',
    },
    textHighlight: {
      width: 28,
      text: '文本高亮',
      icon: 'icon-wenzibeijingyanse',
      values: [
        {
          key: 'rgba(247,211,32,0.4)',
          value: 'rgba(247,211,32,0.4)',
        },
      ],

      showTip: true,
      tooltip: '背景颜色',
      macToolTip: '背景颜色',
    },
    split_line4: {
      width: 17,
    },

    ordered_list: {
      width: 28,
      text: '有序列表',
      icon: 'icon-youxuliebiao11',
      // unsupportedNodes: ['text_align'],
      showTip: true,
      tooltip: '有序列表',
      macToolTip: '有序列表',
    },
    bullet_list: {
      width: 28,
      text: '无序列表',
      icon: 'icon-wuxuliebiao11',
      // unsupportedNodes: ['text_align'],
      showTip: true,
      tooltip: '无序列表',
      macToolTip: '无序列表',
    },
    tabindent: {
      width: 28,
      text: '缩进',
      icon: 'icon-suojin1',
      showTip: true,
      tooltip: '缩进 <br /> Tab',
      macToolTip: '缩进 <br /> Tab',
    },
    decreaseIndent: {
      width: 28,
      text: '取消缩进',
      icon: 'icon-quxiaosuojin1',
      showTip: true,
      tooltip: '取消缩进 <br /> Shift+Tab',
      macToolTip: '取消缩进 <br /> Shift+Tab',
    },
    text_align: {
      width: 36,
      text: '对齐',
      icon: 'icon-zuoduiqi11',
      showTip: true,
      tooltip: '对齐',
      macToolTip: '对齐',
    },
    split_line6: {
      width: 17,
    },
    checklist: {
      width: 28,
      text: '任务列表',
      icon: 'icon-renwuliebiao11',
      // unsupportedNodes: ['text_align'],
      showTip: true,
      tooltip: '任务列表',
      macToolTip: '任务列表',
    },
    table: {
      width: 28,
      text: '表格',
      icon: 'icon-biaoge11',
      showTip: true,
      tooltip: '表格',
      macToolTip: '表格',
    },
    image: {
      width: 28,
      text: '图片',
      icon: 'icon-tupian11',
      showTip: true,
      tooltip: '图片',
      macToolTip: '图片',
    },
    link: {
      width: 28,
      text: '超链接',
      icon: 'icon-lianjie1',
      showTip: true,
      tooltip: '超链接 <br/> Ctrl K',
      macToolTip: '超链接 <br/> ⌘K',
    },
    flowchart: {
      width: 28,
      text: '流程图',
      icon: 'icon-liuchengtu2',
      showTip: true,
      tooltip: '流程图',
      macToolTip: '流程图',
    },
    split_line7: {
      width: 17,
    },
    selectGroup: {
      width: 36,
      children: {
        uploadFileNodes: {
          width: 28,
          text: '文件',
          icon: 'icon-wenjian2',
          showTip: true,
          tooltip: '文件',
          macToolTip: '文件',
        },
        horizontal_rule: {
          width: 28,
          text: '分隔线',
          icon: 'icon-fengexian1',
          showTip: true,
          tooltip: '分隔线',
          macToolTip: '分隔线',
        },
        code_block: {
          width: 28,
          text: '代码块',
          icon: 'icon-daimakuai1',
          showTip: true,
          tooltip: '代码块',
          macToolTip: '代码块',
        },
        expand: {
          width: 28,
          text: '折叠块',
          icon: 'icon-zhediekuai',
          showTip: true,
          tooltip: '折叠块',
          macToolTip: '折叠块',
        },
        blockquote: {
          width: 28,
          text: '引用',
          icon: 'icon-yinyong1',
          showTip: true,
          tooltip: '引用 <br /> Ctrl+Shift+U',
          macToolTip: '引用 <br/> ⌘+Shift+U',
        },
        mathquill: {
          width: 28,
          text: '公式',
          icon: 'icon-gongshi2',
          showTip: true,
          tooltip: '公式',
          macToolTip: '公式',
        },
        contain_page: {
          width: 28,
          text: '包含页面',
          icon: 'icon-wendang1',
          showTip: true,
          tooltip: '包含页面',
          macToolTip: '包含页面',
        },
      },
    },
  },
};

const EditorConfig = {
  schema: {
    nodes: [
      'doc',
      'paragraph',
      'text',
      'blockquote',
      'list_item',
      'bullet_list',
      'ordered_list',
      'heading',
      'image_block',
      'image',
      'hard_break',
      'checklist',
      'checklist_item',
      'code_block',
      'expand',
      'horizontal_rule',
      'file_block',
      'file',
      'mention',
      'flowchart',
      'table',
      'table_cell',
      'table_row',
      'table_header',
      'mathquill',
      'iframe',
      'block_column',
      'block_row',
      'link_card',
      'link_text',
      'contain_page',
    ],

    marks: [
      'link',
      'em',
      'strong',
      'textHighlight',
      'tabindent',
      'strikethrough',
      'underline',
      'textColor',
      'fontsize',
      'code',
      'fontfamily',
      'subscript',
      'superscript',
      'formatBrush',
      'collabUser',
      'formatPainter',
    ],
  },
  placeholder: {
    switch: true,
    content: intl.t('内容'),
  },
  history: false,
  dictionary: false,
  editable: true,
  collab: {
    collabUser: false,
    collabUnderLine: false,
    collabCursor: false,
  },
  comment: false,
  menu: {
    ...MenuConfig,
  },
  supportPreview: [
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'pdf',
    'mp4',
    'mov',
    'mkv',
    'ogg',
    '3gp',
  ],
  offsetTop: 160, // 编辑器距离顶部的距离，便于计算大纲中高亮的标题
  wrapProps: {
    style: {
      height: '100%',
    },
  },
};

export {
  EditorName,
  EditorMicroUrl,
  SaveStatusText,
  CanShowDetail,
  EditorConfig,
  MenuConfig,
  getIframeConfig,
};
