import classNames from 'classnames';
import ApplyPermModal from './ApplyPermModal'
import {
  getPagePermission,
} from '@/service/knowledge/page';
import { intl } from 'di18n-react';
import { useState } from 'react';
import { applyHigherPermFn } from '@/service/knowledge/permission';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';


const ApplyHigherPerm = ({ pageId, perm, isDk, children }) => {
  const [visible, setVisible] = useState();
  const [roleKey, setRoleKey] = useState();
  const [isGlobalPerm, setIsGlobalPerm] = useState(true);

  const notification = useNotification();

  // 保证点击申请弹窗时是最新的权限
  const getCurrMemberMsg = () => {
    if (isDk) {
      getPagePermission({ pageId }).then((res) => {
        let { roleKey: roleKeyData, isSpaceMember } = res;
        if (roleKeyData === 'PAGE_NOPERM_MEMBER') {
          // return {
          //   value: 'PAGE_NOPERM_MEMBER',
          //   roleKeyFront: -1,
          //   label: '无权限成员',
          //   desc: '',
          //   isGlobal: true,
          //   multiple: false,
          // }
          notification(NotificationStatus.ERROR, intl.t('您无法访问该文档，不能申请更多权限'));
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else if (roleKeyData?.length <= 0) {
          // return {
          //   value: 'PAGE_NOPERM_MEMBER',
          //   roleKeyFront: -2,
          //   label: '无权限成员',
          //   desc: '',
          //   isGlobal: true,
          //   multiple: false,
          // }
          notification(NotificationStatus.ERROR, intl.t('您无法访问该文档，不能申请更多权限'));
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          setRoleKey(roleKeyData)
          setIsGlobalPerm(isSpaceMember);
          setVisible(true);
        }
      }).catch((error) => {
        const { errorCode, errorMessage } = error;
        if (errorCode === 300002 || (errorCode === 100001 && errorMessage === '资源不存在')) {
          notification(NotificationStatus.ERROR, intl.t('页面已被删除，3s后为你自动刷新页面'));
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          notification(NotificationStatus.ERROR, errorMessage);
        }
      })
    }
  };


  return <>
    <div
      onClick={() => {
        getCurrMemberMsg()
        try {
          applyHigherPermFn({
            resourceId: pageId,
            isCheck: true,
            adminPerm: '',
            message: '',
            isReApply: false,
          });
        } catch (error) {
          console.log(error);
        }
      }}>
      {children}
    </div>

    {visible && <ApplyPermModal
      roleKey={roleKey}
      isGlobalPerm={isGlobalPerm}
      perm={perm}
      isDk={isDk}
      onClose={() => { setVisible(false); }}
      pageId={pageId}
    />}
  </>
}

export default ApplyHigherPerm;
