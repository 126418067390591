import { intl } from 'di18n-react';

export const DkOptionDefault = () => [{
  value: 'PAGE_READ_MEMBER',
  roleKeyFront: 1,
  label: intl.t('只读成员'),
  desc: '',
  isGlobal: true,
  multiple: false,
}, {
  value: 'PAGE_MEMBER',
  roleKeyFront: 2,
  label: intl.t('成员'),
  desc: '',
  isGlobal: true,
  multiple: false,
}, {
  value: 'PAGE_ADMIN',
  roleKeyFront: 3,
  label: intl.t('管理员'),
  desc: '',
  isGlobal: false,
  multiple: false,
}, {
  value: 'PAGE_OWNER',
  roleKeyFront: 4,
  label: intl.t('所有者'),
  desc: '',
  isGlobal: false,
  multiple: false,
}];

export const cooperOptionDefault = [
  {
    label: intl.t('查看'),
    desc: '',
    value: '1',
    perm: 1,
    checked: false,
    multiple: true,
  },
  {
    label: intl.t('编辑'),
    value: '2',
    perm: 2,
    checked: false,
    multiple: true,
  },
  {
    label: intl.t('上传'),
    value: '8',
    perm: 8,
    checked: false,
    multiple: true,
  },
  {
    label: intl.t('分享'),
    value: '16',
    perm: 16,
    checked: false,
    multiple: true,
  },
  {
    label: intl.t('下载'),
    value: '32',
    perm: 32,
    checked: false,
    multiple: true,
  },
  {
    label: intl.t('管理员'),
    value: '63',
    perm: 63,
    checked: false,
    multiple: false,
  },
  {
    label: intl.t('所有者'),
    value: '72',
    perm: 72,
    checked: false,
    multiple: false,
  },
];


